import { Link, Page, Text, View } from '@react-pdf/renderer';
import { styles } from './styles';
import { Header } from './header';
import { Footer } from './footer';
import moment from 'moment';

export const Page2 = ({ applicant, application, idx }: any) => {
  return (
    <Page size="A4" style={styles.page}>
      <Header applicant={applicant} application={application} />
      {applicant.verification_method[idx] === 'bank_income' ? (
        <>
          {applicant.income_history[idx].incomeSources.map((is: any, index: any) => (
            <>
              <View style={{ marginTop: index > 0 ? 60 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.headingText}>Bank Account {index + 1}</Text>
                <Text style={styles.headingText}>{is.name}</Text>
              </View>
              <View style={styles.summary} wrap={false}>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Start Date of Income Gathered</Text>
                  <Text style={styles.textDarkBold}>{moment(applicant.income_history[idx].start_date).format('MM/DD/YYYY')}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>End Date of Income Gathered</Text>
                  <Text style={styles.textDarkBold}>{moment(applicant.income_history[idx].end_date).format('MM/DD/YYYY')}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Total Earnings</Text>
                  <Text style={styles.textDarkBold}>${is.total ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Transaction Count</Text>
                  <Text style={styles.textDarkBold}>{is.transaction_count ?? 0}</Text>
                </View>
              </View>
              <View style={styles.summaryTable2} wrap={true}>
                <View style={styles.summaryTable2Header}>
                  <Text style={styles.summaryTable2Col1}>Category</Text>
                  <Text style={styles.summaryTable2Mid}>Self Reported</Text>
                  <Text style={styles.summaryTable2Mid}>ProofUp Accepted*</Text>
                  <Text style={styles.summaryTable2Col4}>Payment Frequency</Text>
                </View>
                {is.transactions.map((transaction: any) => (
                  <>
                    <View style={styles.summaryTable2Row} wrap={false}>
                      <Text style={{ ...styles.summaryTable2Col1, color: '#646464' }}>
                        {transaction.income_category === 'OTHER' ? 'MISC' : transaction.income_category}
                      </Text>
                      <Text style={{ ...styles.summaryTable2Mid, color: '#212121' }}>${transaction.total_amount}</Text>
                      <Text
                        style={{
                          ...styles.summaryTable2Mid,
                          color: '#212121',
                          textDecoration: transaction.accepted
                            ? 'none'
                            : transaction.accepted === false
                              ? 'line-through'
                              : transaction.income_category === 'SALARY' ||
                                  transaction.income_category === 'BANK_INTEREST' ||
                                  transaction.income_category === 'GIG_ECONOMY' ||
                                  transaction.income_category === 'MILITARY' ||
                                  transaction.income_category === 'RETIREMENT' ||
                                  transaction.income_category === 'UNEMPLOYMENT'
                                ? 'none'
                                : 'line-through',
                        }}
                      >
                        ${transaction.total_amount}
                      </Text>
                      <Text style={{ ...styles.summaryTable2Col4, color: '#646464' }}>{transaction.pay_frequency}</Text>
                    </View>
                  </>
                ))}
              </View>
              <Text style={{ color: '#7A7A7A', fontSize: 10, marginTop: 8 }}>
                *ProofUp has strict criteria for income verification. We avoid using non-salary or non-invoice transactions such as bank transfers as income.
              </Text>

              <Text style={{ color: '#212121', fontSize: 16, marginTop: 16 }}>Transaction Details</Text>
              <View style={styles.summaryTable2} wrap={true}>
                <View style={styles.summaryTable2Header}>
                  <Text style={styles.summaryTable2Col1}>Date</Text>
                  <Text style={styles.summaryTable2Col1}>Name</Text>
                  <Text style={styles.summaryTable2Col1}>Description</Text>
                  <Text style={styles.summaryTable2Mid}>Amount</Text>
                </View>
                {is.transactions?.map((transaction: any) => (
                  <>
                    {transaction.historical_summary?.map((hs: any) => (
                      <>
                        {hs.transactions?.map((sTransaction: any) => (
                          <>
                            <View style={styles.summaryTable2Row} wrap={false}>
                              <Text style={{ ...styles.summaryTable2Col1, color: '#212121' }}>{sTransaction.date}</Text>
                              <Text style={{ ...styles.summaryTable2Mid, color: '#212121' }}>{sTransaction.name}</Text>
                              <Text style={{ ...styles.summaryTable2Mid, color: '#212121' }}>{sTransaction.original_description}</Text>
                              <Text
                                style={{
                                  ...styles.summaryTable2Col4,
                                  color: '#212121',
                                }}
                              >
                                ${sTransaction.amount}
                              </Text>
                            </View>
                          </>
                        ))}
                      </>
                    ))}
                  </>
                ))}
              </View>
            </>
          ))}
        </>
      ) : applicant.verification_method[idx] === 'paystub' || applicant.verification_method[idx] === 'payroll' ? (
        <>
          {applicant.income_history[idx]?.paystubs?.map((is: any, index: any) => (
            <View wrap={false}>
              <View style={{ marginTop: index > 0 ? 60 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.headingText}>Paystub {index + 1}</Text>
                {is.document_metadata.download_url && (
                  <Link style={{ fontSize: '14px', fontWeight: 500 }} href={is.document_metadata.download_url}>
                    View Upload
                  </Link>
                )}
              </View>
              <View style={styles.summary} wrap={false}>
                {applicant.verification_method[idx] === 'paystub' ? (
                  <View style={styles.summaryRow}>
                    <Text style={styles.textLight}>Document Scan</Text>
                    {is.document_metadata.fraud || is.document_metadata.fraud_reasons?.length ? (
                      <Text style={styles.issuesDoc}>Issues found</Text>
                    ) : (
                      <Text style={styles.cleanDoc}>Clean</Text>
                    )}
                  </View>
                ) : (
                  <></>
                )}
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Start Date of Pay Period</Text>
                  <Text style={styles.textDarkBold}>{moment(is.pay_period_details.start_date).format('MM/DD/YYYY')}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>End Date of Pay Period</Text>
                  <Text style={styles.textDarkBold}>{moment(is.pay_period_details.end_date).format('MM/DD/YYYY')}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Pay Date</Text>
                  <Text style={styles.textDarkBold}>{moment(is.pay_period_details.pay_date).format('MM/DD/YYYY')}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Gross Earnings</Text>
                  <Text style={styles.textDarkBold}>${is.pay_period_details.gross_earnings ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Deductions</Text>
                  <Text style={styles.textDarkBold}>${is.deductions.total.current_amount ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Net Pay</Text>
                  <Text style={styles.textDarkBold}>${is.pay_period_details.pay_amount ?? 0}</Text>
                </View>
              </View>
              {is.document_metadata.fraud ? (
                <View style={styles.fraudError}>
                  {is.document_metadata.fraud_reasons.map((fr: any) => (
                    <Text style={styles.fraudErrorText}>{fr}</Text>
                  ))}
                </View>
              ) : (
                <></>
              )}
              {is.document_metadata.status === 'DOCUMENT_ERROR' ? (
                <View style={styles.fraudError}>
                  <Text style={styles.fraudErrorText}>Unknown Document</Text>
                </View>
              ) : (
                <></>
              )}
            </View>
          ))}
        </>
      ) : applicant.verification_method[idx] === 'w2' ? (
        <>
          {applicant.income_history[idx]?.w2s?.map((is: any, index: any) => (
            <View wrap={false}>
              <View style={{ marginTop: index > 0 ? 60 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.headingText}>Upload {index + 1}</Text>
                {is.document_metadata.download_url && (
                  <Link style={{ fontSize: '14px', fontWeight: 500 }} href={is.document_metadata.download_url}>
                    View Upload
                  </Link>
                )}
              </View>
              <View style={styles.summary} wrap={false}>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Document Scan</Text>
                  {is.document_metadata.fraud || is.document_metadata.fraud_reasons?.length ? (
                    <Text style={styles.issuesDoc}>Issues found</Text>
                  ) : (
                    <Text style={styles.cleanDoc}>Clean</Text>
                  )}
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Document Type</Text>
                  <Text style={styles.textDarkBold}>W2</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Company Name</Text>
                  <Text style={styles.textDarkBold}>{is.employer.name}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Company Location</Text>
                  <Text style={styles.textDarkBold}>
                    {is.employer?.address?.city}, {is.employer?.address?.region}
                  </Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Tax Year</Text>
                  <Text style={styles.textDarkBold}>{is.tax_year}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Monthly Average</Text>
                  <Text style={styles.textDarkBold}>${(is.wages_tips_other_comp / 12).toFixed(2) ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>3 Month Average</Text>
                  <Text style={styles.textDarkBold}>${(is.wages_tips_other_comp / 4).toFixed(2) ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Annual Gross</Text>
                  <Text style={styles.textDarkBold}>${is.wages_tips_other_comp ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Annual Federal Income Tax Withheld</Text>
                  <Text style={styles.textDarkBold}>${is.federal_income_tax_withheld ?? 0}</Text>
                </View>
              </View>
              {is.document_metadata.fraud ? (
                <View style={styles.fraudError}>
                  {is.document_metadata.fraud_reasons.map((fr: any) => (
                    <Text style={styles.fraudErrorText}>{fr}</Text>
                  ))}
                </View>
              ) : (
                <></>
              )}
              {is.document_metadata.status === 'DOCUMENT_ERROR' ? (
                <View style={styles.fraudError}>
                  <Text style={styles.fraudErrorText}>Unknown Document</Text>
                </View>
              ) : (
                <></>
              )}
            </View>
          ))}
        </>
      ) : applicant.verification_method[idx] === '1099' ? (
        <>
          {applicant.income_history[idx]?.form1099s?.map((is: any, index: any) => (
            <View wrap={false}>
              <View style={{ marginTop: index > 0 ? 60 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.headingText}>Upload {index + 1}</Text>
                {is.document_metadata.download_url && (
                  <Link style={{ fontSize: '14px', fontWeight: 500 }} href={is.document_metadata.download_url}>
                    View Upload
                  </Link>
                )}
              </View>
              <View style={styles.summary} wrap={false}>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Document Scan</Text>
                  {is.document_metadata.fraud || is.document_metadata.fraud_reasons?.length ? (
                    <Text style={styles.issuesDoc}>Issues found</Text>
                  ) : (
                    <Text style={styles.cleanDoc}>Clean</Text>
                  )}
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Document Type</Text>
                  <Text style={styles.textDarkBold}>1099</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Company Name</Text>
                  <Text style={styles.textDarkBold}>{is.payer.name}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Company Location</Text>
                  <Text style={styles.textDarkBold}>
                    {is.payer?.address?.city}, {is.payer?.address?.region}
                  </Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Tax Year</Text>
                  <Text style={styles.textDarkBold}>{is.tax_year}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Monthly Average</Text>
                  <Text style={styles.textDarkBold}>${(is.wages_tips_other_comp / 12).toFixed(2) ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>3 Month Average</Text>
                  <Text style={styles.textDarkBold}>${(is.wages_tips_other_comp / 4).toFixed(2) ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Annual Gross</Text>
                  <Text style={styles.textDarkBold}>${is.wages_tips_other_comp ?? 0}</Text>
                </View>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Annual Federal Income Tax Withheld</Text>
                  <Text style={styles.textDarkBold}>${is.federal_income_tax_withheld ?? 0}</Text>
                </View>
              </View>
              {is.document_metadata.fraud ? (
                <View style={styles.fraudError}>
                  {is.document_metadata.fraud_reasons.map((fr: any) => (
                    <Text style={styles.fraudErrorText}>{fr}</Text>
                  ))}
                </View>
              ) : (
                <></>
              )}
              {is.document_metadata.status === 'DOCUMENT_ERROR' ? (
                <View style={styles.fraudError}>
                  <Text style={styles.fraudErrorText}>Unknown Document</Text>
                </View>
              ) : (
                <></>
              )}
              {is.document_metadata.status === 'INCORRECT_DOC' ? (
                <View style={styles.fraudError}>
                  <Text style={styles.fraudErrorText}>Unknown Document</Text>
                </View>
              ) : (
                <></>
              )}
            </View>
          ))}
        </>
      ) : applicant.verification_method[idx] === 'Document Upload' ? (
        <>
          {applicant.income_history[idx]?.items?.map((is: any, index: any) => (
            <View wrap={false}>
              <View style={{ marginTop: index > 0 ? 60 : 0, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text style={styles.headingText}>
                  {is.typeR} {index + 1}
                </Text>
                <Link style={{ fontSize: '14px', fontWeight: 500 }} href={is.file}>
                  View Upload
                </Link>
              </View>
              <View style={styles.summary} wrap={false}>
                <View style={styles.summaryRow}>
                  <Text style={styles.textLight}>Document Scan</Text>
                  {is.typeR === 'Fraud Document' || is.typeR === 'Invalid Document' ? (
                    <Text style={styles.issuesDoc}>Issues found</Text>
                  ) : (
                    <Text style={styles.cleanDoc}>Clean</Text>
                  )}
                </View>
                {is.data && (is.data?.period_begin || is.data?.statement_start) ? (
                  <View style={styles.summaryRow}>
                    <Text style={styles.textLight}>Start Date of Pay Period</Text>
                    <Text style={styles.textDarkBold}>{moment(is.data?.period_begin ?? is.data?.statement_start).format('MM/DD/YYYY')}</Text>
                  </View>
                ) : (
                  <></>
                )}
                {is.data && (is.data?.period_end || is.data?.statement_end) ? (
                  <View style={styles.summaryRow}>
                    <Text style={styles.textLight}>End Date of Pay Period</Text>
                    <Text style={styles.textDarkBold}>{moment(is.data?.period_end ?? is.data?.statement_end).format('MM/DD/YYYY')}</Text>
                  </View>
                ) : (
                  <></>
                )}
                {is.data && is.data.gross_income ? (
                  <View style={styles.summaryRow}>
                    <Text style={styles.textLight}>Gross Earnings</Text>
                    <Text style={styles.textDarkBold}>${is.data.gross_income ?? 0}</Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
              {is.typeR === 'Fraud Document' ? (
                <View style={styles.fraudError}>{is.data?.fraud_reasons.map((fr: any) => <Text style={styles.fraudErrorText}>{fr}</Text>)}</View>
              ) : (
                <></>
              )}
              {is.typeR === 'Invalid Document' ? (
                <View style={styles.fraudError}>
                  <Text style={styles.fraudErrorText}>Unknown Document</Text>
                </View>
              ) : (
                <></>
              )}
            </View>
          ))}
        </>
      ) : (
        <></>
      )}

      <Footer />
    </Page>
  );
};
